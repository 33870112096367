@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,500;1,300;1,500&display=swap');

body {
	padding:0; margin:0; font-size:14px; background-color:#FFF;
	font-family:'Montserrat', sans-serif; color:#414e59; font-weight:400;
}

.clear 	{clear:both; display:block; overflow:hidden; visibility:hidden; width: 0; height:0;}

.text_60     {font-size:60px;}
.text_40     {font-size:40px;}
.text_35     {font-size:35px;}
.text_30     {font-size:30px;}
.text_25     {font-size:25px;}
.text_22     {font-size:22px;}
.text_20     {font-size:20px;}
.text_18     {font-size:18px;}
.text_16     {font-size:16px;}
.text_14     {font-size:14px;}
.text_12     {font-size:12px;}
.text-justify{text-align:justify;}

.under_line       {text-decoration:underline;}
.under_line:hover {text-decoration:none;}

.Montserrat {font-family:'Montserrat', sans-serif;}
.Poppins    {font-family:'Poppins', sans-serif;}
.Roboto     {font-family:'Roboto', sans-serif;}

.page-header {padding:.7rem 0; background-color:#F4F5F9;}
.page-header .logo {
	color:#007843; display:flex; align-items:center;
	-webkit-box-align:center; -ms-flex-align:center;
}
.page-header .logo h1 {
	font-family:'Roboto', sans-serif; font-size:24px;
	color:#007843; margin-bottom:0;
}
.page-header .account_section {
	list-style:none;
	text-align:right;
	margin:0; padding:0;
	align-items:center;
}
.page-header .account_section li {display:inline-block; padding:0 7px; vertical-align:middle; position:relative;}
.page-header .account_section li:first-child {border-right:#EEE 1px solid;}
.page-header .account_section li i {font-size:17px;}
.page-header .account_section li.register,
.page-header .account_section li.login {padding:0;}
.page-header .account_section li.register > a {
	display:block;
	background-color:transparent;
	border:#119A63 1px solid;
	border-radius:3px;
	color:#119A63;
	text-decoration: none;
	padding:7px 15px;
	font-size:16px;
	font-family:'Poppins', sans-serif;
	font-weight:500;
}
.page-header .account_section li.register > a:hover,
.page-header .account_section li.register > a:focus {
	background-color:#119A63; color:#FFF;
}
.page-header .account_section li.login > a {
	display:block;
	background-color:#FF7F57;
	border:#FF7F57 1px solid;
	border-radius:3px;
	color:#FFF;
	text-decoration: none;
	padding:7px 15px;
	font-size:16px;
	font-family:'Poppins', sans-serif;
	font-weight:500;
}
.page-header .account_section li.login > a:hover,
.page-header .account_section li.login > a:focus {
	background-color:#F5B700; border-color:#F5B700; color:#FFF;
}

.banner {
	background:#F4F5F9 url("./assets/images/banner_bg.png") center no-repeat fixed;
	background-size:cover; padding:1.5rem 0; padding-bottom:12px;
}

#alertslides .carousel-item img {display:block; max-width:100%;	border-radius:10px; margin-bottom:20px}
#alertslides .carousel-item .carousel-caption {
  	right:32%; bottom:10px; left:10px; padding-top:0;
  	padding-bottom:0; color:#313131; text-align:left; top:10px;
}
#alertslides .carousel-item .carousel-caption p {margin-bottom:5px;}
#alertslides .carousel-item .carousel-caption h5{color:#000; font-weight:700; font-size:16px;}
#alertslides .carousel-item .carousel-caption a {color:#000; font-weight:700;}
#alertslides .carousel-control-next, #alertslides .carousel-control-prev {
    position:absolute; top:45px; right:-15px; bottom:auto; width:30px;
	background-color:#000; border-radius:50%; height:30px; line-height:30px;
	border-color:#FFF;
}
#alertslides .carousel-control-prev {left:-15px;}

.highlight                {padding-left:20px; margin:0; max-height:333px; overflow-y:auto;}
.highlight li             {border-bottom:#EEE 1px solid; padding-top:10px; padding-bottom:13px;}
.highlight li a           {color:#313131;}
.highlight li a:hover     {color:#007843; text-decoration:underline;}
.highlight li:first-child {padding-top:0;}
.highlight li:last-child  {padding-bottom:0; border-bottom:none;}
.highlight li::marker     {color:red; font-size:1.5em;}

.bg-light.h-100.p-4       {min-height:50rem;}

.bg-warning {background-color:#FF7F57 !important;}
.bg-info    {background-color:#377DFF !important;}

.banner_widgets {
	display:block; border-radius:.55rem; color:#414e59;
	box-shadow:0 0.1rem 0.5rem rgba(0,0,0,.07);
	background-color:#fff; margin-bottom:10px; padding:15px;
	background-clip:border-box; font-size:12.5px;
	border:1px solid rgba(0,0,0,.125); font-weight:600;
	white-space:nowrap; min-height:90px;
}
.banner_widgets .icon {
	width:60px; height:60px;
	float:left; margin-right:15px;
	border-radius:10px; line-height:75px;
	text-align:center; display:block;
	box-shadow:0 0.1rem 0.5rem rgba(0,0,0,.07);
}
.banner_widgets h3                {font-size:24px; margin-top:7px; font-weight:600; margin-bottom:0;}
.banner_widgets .icon img         {width:40px; margin-top:10px;}
.banner_widgets.success .icon     {background-color:#11CD8C; box-shadow:0 0.1rem 0.5rem rgba(17, 154, 99, 0.27);}
.banner_widgets.success h3        {color:#11CD8C;}
.banner_widgets.success:hover     {border-color:#11CD8C; background-color:#DFF9F0;}
.banner_widgets.danger .icon      {background-color:#FF515A; box-shadow:0 0.1rem 0.5rem rgb(225, 192, 193)}
.banner_widgets.danger h3         {color:#FF515A;}
.banner_widgets.danger:hover      {border-color:#FF515A; background-color:#FBEAEB;}
.banner_widgets.warning .icon     {background-color:#FF7F57; box-shadow:0 0.1rem 0.5rem rgb(213, 183, 174)}
.banner_widgets.warning h3        {color:#FF7F57;}
.banner_widgets.warning:hover     {border-color:#FF7F57; background-color:#FDEBE6;}
.banner_widgets.brown .icon       {background-color:#D86D39; box-shadow:0 0.1rem 0.5rem rgb(210, 189, 178)}
.banner_widgets.brown h3          {color:#D86D39;}
.banner_widgets.brown:hover       {border-color:#D86D39; background-color:#FBEFE9;}
.banner_widgets.primary .icon     {background-color:#14496B; box-shadow:0 0.1rem 0.5rem rgb(193, 210, 221);}
.banner_widgets.primary h3        {color:#14496B;}
.banner_widgets.primary:hover     {border-color:#14496B; background-color:#E8F5FD;}
.banner_widgets.info .icon        {background-color:#00c0ef; box-shadow:0 0.1rem 0.5rem rgb(225, 242, 253);}
.banner_widgets.info h3           {color:#00c0ef;}
.banner_widgets.info:hover        {border-color:#00c0ef; background-color:#ebf7fa;}
.banner_widgets.secondary .icon   {background-color:#6c757d; box-shadow:0 0.1rem 0.5rem rgb(228, 228, 228);}
.banner_widgets.secondary h3      {color:#6c757d;}
.banner_widgets.secondary:hover   {border-color:#6c757d; background-color:#efefef;}

.banner_search  {
	box-shadow:0 0.1rem 0.5rem rgba(0,0,0,.07);
	background-color:#FFF; padding:5px 10px; border-radius:80px;
}
.banner_search .form-group {margin-bottom:0;}
.banner_search .form-group label {padding-left:15px; margin-bottom:0px;}
.banner_search .form-group .form-control {border:none; border-radius:0; background-color:transparent;}
.banner_search .btn {width:60px; height:60px; border-radius:50%; font-size:1.5em;}

.announcement_box {position:relative; overflow:hidden; border-radius:10px; margin-bottom:30px;}
.announcement_box img {display:block; max-width:100%; transition:transform .5s;}
.announcement_box:hover img {transform: scale(1.15);}
.announcement_box .bar {height:3px; width:100px; display:block; margin-bottom:5px;}
.announcement_box a    {
	display:flex; flex-wrap:wrap;
	-webkit-box-align:end !important;
	-ms-flex-align:end !important;
	align-items:flex-end !important;
	color:#FFF; text-decoration:none;
	position:absolute; left:0; top:0;
	right:0; bottom:0; z-index:99;
	padding:20px; transition:transform .5s;
	background-image:var(--mask-gradient, linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0,0,0,0) 70%));
}
.announcement_box:hover a {
	color:#FFF;
	background-image:var(--mask-gradient, linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0,0,0,.5) 100%));
}

.services_widgets {
	display:block; color:#14496B;
	border:2px solid rgba(255,255,255,0.44);
	border-radius:10px; font-weight:600;
	font-size:18px; color:#14496B; padding:10px 15px;
	box-shadow:0 0.1rem 0.5rem rgba(0,0,0,.07);
}
.services_widgets img {
	width:100px; height:100px; margin-right:20px;
	border:rgba(255,255,255,0.44) 2px solid; border-radius:50%;
}
.services_widgets.blue {background-color:#deecfb;}
.services_widgets.blue:hover {border-color:#81D3FF; background-color:#81D3FF;}
.services_widgets.orrange {background-color:rgb(249, 212, 198);}
.services_widgets.orrange:hover {border-color:#FFAD8A; background-color:#FFAD8A;}
.services_widgets.green {background-color:rgb(90, 214, 193, 0.30);}
.services_widgets.green:hover {border-color:#5AD6C1; background-color:#5AD6C1;}
.services_widgets.yellow {background-color:rgb(255, 225, 148, 0.30);}
.services_widgets.yellow:hover {background-color:#FFE194; border-color:#FFE194;}
.services_widgets.gray {background-color:rgb(227, 227, 227, 0.30);}
.services_widgets.gray:hover {border-color:#E3E3E3; background-color:#E3E3E3;}
.services_widgets.green2 {background-color:rgb(150, 219, 224, 0.30);}
.services_widgets.green2:hover {border-color:#96DBE0; background-color:#96DBE0;}

.home-tabz .nav-link {color:#14496B; border:#14496B 1px solid; min-width:135px; text-align:center;}
.home-tabz li:first-child .nav-link {border-radius:.25rem 0 0 .25rem;}
.home-tabz li:last-child .nav-link  {border-radius:0 .25rem .25rem 0;}
.home-tabz .nav-link.active {background-color:#14496B; border-color:#14496B;}

#carouselExplore .carousel-control-next,
#carouselExplore .carousel-control-prev {
	top:-60px; bottom:auto; left:auto; right:40px; font-size:30px; color:#CCC; width:30px; opacity:0.7;
}
#carouselExplore .carousel-control-next {right:0;}
#carouselExplore .carousel-control-next:focus,
#carouselExplore .carousel-control-next:hover,
#carouselExplore .carousel-control-prev:focus,
#carouselExplore .carousel-control-prev:hover {color:#119A63;}

.side_pipe {position:absolute; left:0; top:20px; bottom:20px; width:5px;}

.gallery_pic {
	min-height:155px; display:inline-block; max-width:100%;
	margin-left:auto; margin-right:auto; cursor:pointer;
}

.footer {position:relative; color:#FFF; background-color:#191A1F; font-size:12px;}
.footer .uilinks {padding-left:10px;}
.footer .uilinks > li {padding:5px 0;}
.footer h6 {font-weight:600; color:#FFF;}
.footer a {color:#FFF; text-decoration:none;}
.footer a:hover, .footer a:focus {color:#11CD8C;}

.mobile  {display:none;}

/*---From Bootstrap--*/

a {
	color:#119A63; text-decoration:none;
	cursor:pointer;
	-webkit-transition:all 0.3s ease-out;
  	-moz-transition:all 0.3s ease-out;
  	-o-transition:all 0.3s ease-out;
  	transition:all 0.3s ease-out;
}
a:hover, a:focus {text-decoration:none; color:#000}
a:focus          {outline:none;}

.img-center      {display:inline-block; max-width:100%; margin-left:auto; margin-right:auto;}
.img-circle      {border-radius:50%; border:#FFF 7px solid; max-width:100%;}

.container       {max-width:1270px;}

.badge-pill      {border-radius:50%; font-weight:300;}
.badge-success   {background-color:#11CD8C;}

.rounded-pill    {border-radius:50rem !important;}

.dropdown-item   {font-weight:300; font-size:14px; padding: .45rem 1.5rem;}

h1, h2, h3 	{font-weight:700; font-family:'Poppins', sans-serif; color:#475677;}
h4, h5, h6 	{font-weight:700; font-family:'Poppins', sans-serif;}
h1, .h1 	{font-size:40px;}
h2, .h2 	{font-size:36px;}
h3, .h3 	{font-size:30px;}
h4, .h4 	{font-size:24px;}
h5, .h5 	{font-size:20px;}
h6, .h6 	{font-size:16px;}

.text-success {color:#119A63 !important;}
.text-danger  {color:#FF515A !important;}
.text-warning {color:#FF7F57 !important;}
.text-primary {color:#11386F !important;}
.text-info    {color:#377DFF !important;}
.text-black-50{color:rgba(0,0,0,0.3) !important;}
.text-black   {color:#000 !important;}
.text-body    {color:#414e59 !important;}
.text-light   {color:#FFF !important;}

.transform_upper {text-transform:uppercase;}
.transform_none  {text-transform:none;}

.font-weight-light     {font-weight:300!important}
.font-weight-normal    {font-weight:400!important}
.font-weight-medium    {font-weight:500!important}
.font-weight-semi-bold {font-weight:600!important}
.font-weight-bold      {font-weight:700!important}

.text-break {word-wrap:break-word!important; word-break:break-word!important;}
.text-wrap  {white-space:normal!important;}
.text-nowrap{white-space:nowrap!important;}

.bg-primary   {background-color:#5181db !important; color:#FFF;}
.bg-Searchable   {background-color:#f6fa09 !important; color:#000000;}
.bg-light     {background-color:#F4F5F9 !important;}
.bg-danger    {background-color:#e62b4c !important; color:#FFF;}

hr            {margin:15px 0; border-top:#e4e4e4 1px solid; border-bottom:#FFF 1px solid;}

.card {
	border:none; border-radius:.55rem;
	box-shadow:0 0.1rem 0.5rem rgba(0,0,0,.07);
	margin-bottom:30px;
}
.card .card-header {
	background-color:#B1DBCE; font-weight:600;
	border-bottom-color:rgb(143, 189, 175);
	border-radius: calc(.55rem - 1px) calc(.55rem - 1px) 0 0;
}
.accordion .card-header button.btn {white-space:pre-wrap;}

.form-control {height:calc(2.6rem + 2px); font-weight:400;}
.form-control-lg {
	height:calc(3.1rem + 2px);
	font-weight:600;
	padding:.5rem 1rem;
    font-size:1.25rem;
    line-height:1.5;
    border-radius:.3rem;
}
.form-control-sm {
	height:calc(1.8125rem + 2px);
    padding:.25rem .5rem;
    font-size:.875rem;
    line-height:1.5;
    border-radius:.2rem;
}

.navbar-light {background-color: #b2dbce; padding-top: 0; padding-bottom: 0;}
.navbar-light .navbar-nav > li {position:relative;}
.navbar-light .navbar-nav > li::after {
	content:''; position:absolute; width:1px;
	background-color:#FFF; right:0; top:5px; bottom:5px;
}
.navbar-light .navbar-nav > li:last-child::after {display: none;}
.navbar-light .navbar-nav > li:last-child.dropdown .dropdown-menu {left:auto; right:0;}
.navbar-light .navbar-nav .nav-link {
	color:#005D38; font-weight:500; padding:0.8rem 0.5rem; font-size:16px;
	font-family:'Poppins', sans-serif; position:relative; z-index:10; white-space:nowrap;
}

.navbar-light .navbar-nav .nav-link .badge {
	position:absolute; top:5px; right:5px;
	font-weight:300; border-radius:50%;
}
.navbar-light .navbar-nav li:hover > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color:#FFF; background-color:#119A63; font-weight:600;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item {font-weight:400; padding:.75rem 1rem;}

.navbar-light .navbar-nav .nav-link:hover {color:#FFF !important; background-color: #119A63;}
.dropdown-menu {transition:all .3s ease 0s;}
.subnavbar {background-color:#d7edf2;}
.subnavbar ul.nav {border-left:#FFF 1px solid;}
.subnavbar li.nav-item {border-right:#FFF 1px solid;border-bottom:#FFF 1px solid;}
.subnavbar li.nav-item a.nav-link {color:#036; text-decoration:none; font-weight:500; font-size:13px;}
.subnavbar li.nav-item:hover > .nav-link,
.subnavbar li.nav-item.active > .nav-link,
.subnavbar li.nav-item .nav-link.active,
.subnavbar li.nav-item .nav-link.show,
.subnavbar li.nav-item.show > .nav-link {color:#FFF; background-color:#036;}
.subnavbar .navbar-toggler {color:rgba(0, 0, 0, .5); border-color:rgba(0, 0, 0, .1); margin:5px 15px;}


.badge {font-weight: 600;}
.btn {
	padding:.5rem 1rem; font-size:16px; font-weight:400;
	box-shadow:0 0.5rem 1rem rgba(0,0,0,0.15); cursor: pointer;
}
.btn:active,.btn:focus,.btn:hover {box-shadow:none;}
.btn-group-sm > .btn, .btn-sm{padding:.25rem .5rem !important; font-size:.8rem !important; line-height:1.5; border-radius:.2rem;}
.btn-group-lg>.btn, .btn-lg  {border-radius:.5rem; font-weight:bold; font-size:18px; padding:.7rem 1rem; border-width:2px;}
.btn.disabled, .btn:disabled {opacity:0.25;}
.btn-rounded                 {border-radius:1.8rem;}
.btn-link                    {box-shadow:none !important;}
.btn-warning                 {color:#3c3f46; background-color:#fdb817; border-color:#fdb817;}
.btn-outline-warning         {color:#fdb817; border-color:#fdb817;}
.btn-danger                  {background-color:#e62b4c; border-color:#e62b4c;}
.btn-outline-danger          {color:#e62b4c; border-color:#e62b4c;}
.btn-success                 {background-color:#31B982; border-color:#31B982; color:#FFF;}
.btn-outline-success         {color:#31B982; border-color:#31B982;}
.btn-black                   {background-color:#282828; border-color:#282828; color:#FFF;}
.btn-primary                 {background-color:#175497; border-color:#175497;}
.btn-outline-primary         {border-color:#175497; color:#175497;}
.btn-outline-primary:hover,
.btn-outline-primary:focus   {background-color:#175497; border-color:#175497; color:#FFF !important;}


.btn-100 {min-width:100px;}
.btn-150 {min-width:150px;}
.btn-200 {min-width:200px;}

.modal                                     {z-index:9999;}
.modal-dialog                              {max-width:600px;}
.modal-sm                                  {max-width:380px;}
.modal-lg                                  {max-width:800px;}
.modal-xl                                  {max-width:1200px;}
.modal-footer                              {justify-content:left;}
.modal-footer.text-center                  {justify-content:center;}
.modal-footer > .btn                       {min-width:120px;}
.modal-footer > .btn-outline-success,
.modal-footer > .btn-outline-danger,
.modal-footer > .btn-outline-primary       {background-color:transparent;}
.modal-footer > .btn-outline-success:hover {background-color:#28a745 !important;}
.modal-footer > .btn-outline-danger:hover  {background-color:#dc3545 !important;}
.modal-footer > .btn-outline-primary:hover {background-color:#007bff !important;}
.modal-title                               {text-transform:none;}

.modal-dialog-scrollable .modal-content    {max-height: calc(100vh - 1rem); overflow: hidden;}
.modal-dialog-scrollable .modal-body       {overflow-y:auto;}

.close {font-weight:600; color:#f00;}

label                  {font-weight:500; color:#000;}
.form-control          {font-size:0.9rem; font-weight:300;}
.form-control.grey     {background-color:#f0f0f0;}
.form-control.green    {border-color:#24c960;}

.navbar-toggler {padding:.25rem .5rem;}

.table                      {font-size:14px;}
.table th, .table td        {border-top:none; border-bottom:1px solid #dee2e6;}
.table-hover tbody tr:hover {background-color:rgba(0, 0, 0, 0.03);}

.sortTh {display:flex;}
.sortTh .sorted_Icons {display:flex; flex-flow:column; margin-left:1rem;}

/*.sortable > thead > tr > th {position:relative;}
.sortable > thead > tr > th::before, .sortable > thead > tr > th::after {
	position:absolute; top:50%;	right:10px; display:block; opacity:.5;
	content:""; border-top:0.3em solid;
    border-right:0.3em solid transparent;
    border-bottom:0;
    border-left:0.3em solid transparent;
}
.sortable > thead > tr > th::before {margin-top:-5px; transform:rotate(180deg);}
.sortable > thead > tr > th::after  {margin-bottom:-5px;}*/

.dropdown-menu {box-shadow:0 0.1rem 0.5rem rgba(0,0,0,.2);}
.dropdown-item.active, .dropdown-item.active:hover, .dropdown-item:active {
	background-color:#119A63 !important; color:#FFF !important;
}

.list-group-item.active {
	color:#005D38; font-weight:600;
	background-color:#b2dbce;
	border-color:#b2dbce;
	border-left:#119A63 4px solid;
}

.well {
    min-height:20px; padding:19px; margin-bottom:20px;
    background-color:#f5f5f5; border:1px solid #e3e3e3;
    border-radius:4px; color:#414558;
    -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
}

.switch {position:relative; display:inline-block; width:60px; height:34px;}
.switch input {opacity:0; width:0; height:0;}
.slider {
	position:absolute; cursor:pointer; top:0;
	left:0; right:0; bottom:0;border-radius:34px;
	background-color:#ccc; transition:.4s;
	-webkit-transition:.4s;	
}
.slider:before {
	position:absolute; content:''; height:26px; width:26px;
	left:4px; bottom:4px; border-radius:50%; transition:.4s;
	background-color:white; -webkit-transition:.4s;
}
input:checked + .slider {background-color:#e62b4c;}
input:focus + .slider   {box-shadow:0 0 1px #e62b4c;}
input:checked + .slider:before {
	-webkit-transform:translateX(26px);
	-ms-transform:translateX(26px);
	transform:translateX(26px);
}

.page-link {color:#005D38;}
.page-item.active .page-link {background-color:#b2dbce; border-color:#b2dbce; color:#005D38;}


.arial-pagination {
    padding:1rem 0 0.3rem 0;
    display:flex;
    width:100%;
    overflow-x:auto;
    white-space:nowrap;
    align-items:center;
	cursor: pointer;
}
.arial-pagination a.page-link.active {color:#119A63; font-weight:600; background-color:#e9efea;}
.arial-pagination .page-item > .page-link {display:none;}
.arial-pagination .page-item:first-child > .page-link,
.arial-pagination .page-item:last-child > .page-link,
.arial-pagination .page-item > .page-link.active {display:block;}

.chart {position:relative;}
.chart > strong {color:#FFF; font-size:36px; position:absolute; width:100px; text-align:center; display:block;}
.chart > strong.all {top:136px; left:297px;}
.chart > strong.non-consultancy {top:245px; left:85px;}
.chart > strong.goods {top:418px; left:77px;}
.chart > strong.works {bottom:58px; left:286px;}
.chart > strong.consultancy {top:360px; right:82px;}

@media (max-width:767px) {
	.page-header .account_section li {display:none;}
	.page-header .account_section li.login,
	.page-header .account_section li.register{display: inline-block; padding:8px 0 0 0; width:49%; text-align: center;}
	.navbar-light .navbar-nav > li {text-align: left;}
	.navbar-light .navbar-nav > li::after {display: none;}
	#alertslides .carousel-item .carousel-caption {right:25%;}
	#alertslides .carousel-item .carousel-caption p {display:none;}
	#alertslides .carousel-item .carousel-caption p + a {display:none;}
	.banner_search {border-radius:8px;}
	.banner_search .form-group {margin-bottom:0; width:49% !important;}
	.banner_search .btn {width:auto; height:auto; border-radius:7px; margin-top:0;}
}

@media (min-width:992px) {
	.navbar-nav .dropdown:hover > .dropdown-menu {display:block !important;}
	.navbar-light .navbar-nav .dropdown-menu {top:95%;}
	.navbar-light .navbar-nav .dropdown .dropdown .dropdown-toggle::after {
		border-top:4px solid transparent; border-bottom:4px solid transparent;  
		border-left:4px solid #222; float:right; margin-top:6px;
	}
	.navbar-light .navbar-nav .dropdown-menu .dropdown-menu {
		padding-top:0; padding-bottom:0; top:0; left:99%;
	}
	.navbar-light .navbar-nav .dropdown-item:focus, 
	.navbar-light .navbar-nav .dropdown-item:hover {
		color:#005D38; background-color:#d9ece6;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.page-header .account_section li.br {display:block;}
	.page-header .account_section li.login,
	.page-header .account_section li.register{width:150px; text-align:center;}
}
.ppra-body {
	background:#FFF url("./assets/images/back.jpg") center repeat fixed !important;
    font-family:'Times New Roman', Times, serif !important; min-height:100vh;
    font-size:16px !important; color:#000 !important;
}

.underline {
    text-decoration: underline;
}


